import { ApplicationRef, InjectionToken, StaticProvider } from '@angular/core';

export interface NgSetupOptions {
  bootstrap: () => Promise<ApplicationRef>;
  outputPath: string;
  baseHref?: string;
  document?: string;
  documentFilePath?: string;
  providers?: StaticProvider[];
  inlineCriticalCss?: boolean;
}

export interface RenderOptions {
  document?: string;
  providers?: StaticProvider[];
  inlineCriticalCss?: boolean;
}

export interface CacheStorage {
  set(key: string, value: string, expiresIn: number): any;

  get(key: string): string | null;
}

declare module 'fastify' {
  interface FastifyReply {
    renderAngular: (opts?: RenderOptions) => Promise<string>;
  }
}
export const SERVER_URL_TOKEN = new InjectionToken<any>('UNIVERSAL_SERVER_URL_TOKEN');
export const SERVER_LOGGER_TOKEN = new InjectionToken<any>('UNIVERSAL_SERVER_LOGGER_TOKEN');
export const SERVER_REQUEST_TOKEN = new InjectionToken<any>('UNIVERSAL_SERVER_REQUEST_TOKEN');
export const SERVER_RESPONSE_TOKEN = new InjectionToken<any>('UNIVERSAL_SERVER_RESPONSE_TOKEN');
export const SERVER_CONTEXT = new InjectionToken<string>('SERVER_CONTEXT');
