import { DestroyRef, inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { getBrowserLang, TranslocoService } from '@jsverse/transloco';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationStart, Router, Event } from '@angular/router';
import { filter, pairwise, Subject } from 'rxjs';
import { LOCALES_TOKEN } from '@owain/transloco-utils/localize-router.tokens';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class LocalizeRouterService {
  private readonly locales: Array<string> | undefined = inject(LOCALES_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly location: Location = inject(Location);
  private readonly translocoService: TranslocoService = inject(TranslocoService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public routerEvents: Subject<string> = new Subject<string>();
  private latestUrl: string | undefined;

  constructor() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationStart),
        pairwise()
      )
      .subscribe(this._routeChanged());
  }

  public translateRoute(path: string | any[]): string {
    const lang = this.translocoService.getActiveLang();

    if (typeof path === 'string') {
      const newPath = `/${lang}/${path}`.replace(/\/+/g, '/');

      if (newPath.endsWith('/')) {
        return newPath.slice(0, -1);
      }

      return newPath;
    }

    const newPath = `/${lang}/${path.join('/')}`.replace(/\/+/g, '/');

    if (newPath.endsWith('/')) {
      return newPath.slice(0, -1);
    }

    return newPath;
  }

  public changeLanguage(lang: string): void {
    if (lang !== this.translocoService.getActiveLang()) {
      this.translocoService.setActiveLang(lang);
      const rootSnapshot: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;

      let url = this.traverseRouteSnapshot(rootSnapshot);
      url = this.translateRoute(url) as string;

      // Prevent multiple "/" character
      url = url.replace(/\/+/g, '/');

      const lastSlashIndex = url.lastIndexOf('/');
      if (lastSlashIndex > 0 && lastSlashIndex === url.length - 1) {
        url = url.slice(0, -1);
      }

      this.router.navigateByUrl(`${url}`);
    }
  }

  private traverseRouteSnapshot(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot.firstChild && snapshot.routeConfig) {
      return `${this.parseSegmentValue(snapshot)}/${this.traverseRouteSnapshot(snapshot.firstChild)}`;
    } else if (snapshot.firstChild) {
      return this.traverseRouteSnapshot(snapshot.firstChild);
    }

    return this.parseSegmentValue(snapshot);
  }

  private buildUrlFromSegments(snapshot: ActivatedRouteSnapshot, segments: string[]): string {
    return segments.map((s: string, i: number) => (s.indexOf(':') === 0 ? snapshot.url[i].path : s)).join('/');
  }

  private parseSegmentValue(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot.data['localizeRouter']) {
      const path = snapshot.data['localizeRouter'].path;
      const subPathSegments = path.split('/');
      return this.buildUrlFromSegments(snapshot, subPathSegments);
    } else if (snapshot.parent && snapshot.parent.parent && snapshot.routeConfig) {
      const path = snapshot.routeConfig.path;
      if (path) {
        const subPathSegments = path.split('/');
        return this.buildUrlFromSegments(snapshot, subPathSegments);
      }
    }

    return '';
  }

  getLocationLang(url?: string): string | null {
    const queryParamSplit = (url || this.location.path()).split(/[?;]/);
    let pathSlices: string[] = [];
    if (queryParamSplit.length > 0) {
      pathSlices = queryParamSplit[0].split('/');
    }
    if (pathSlices.length > 1 && this.locales && this.locales.indexOf(pathSlices[1]) !== -1) {
      return pathSlices[1];
    }
    if (pathSlices.length && this.locales && this.locales.indexOf(pathSlices[0]) !== -1) {
      return pathSlices[0];
    }
    return null;
  }

  private _routeChanged(): (eventPair: [NavigationStart, NavigationStart]) => void {
    return ([previousEvent, currentEvent]: [NavigationStart, NavigationStart]) => {
      const previousLang = this.getLocationLang(previousEvent.url) || this.translocoService.getDefaultLang();
      const currentLang = this.getLocationLang(currentEvent.url) || this.translocoService.getDefaultLang();

      if (currentLang && currentLang !== previousLang && this.latestUrl !== currentEvent.url) {
        this.latestUrl = currentEvent.url;
        this.cancelCurrentNavigation();

        // Init new navigation with same url to take new config in consideration
        this.router.navigateByUrl(currentEvent.url);
        // Fire route change event
        this.routerEvents.next(currentLang);
      }
      this.latestUrl = currentEvent.url;
    };
  }

  private cancelCurrentNavigation() {
    const currentNavigation = this.router.getCurrentNavigation();
    if (!currentNavigation) {
      return;
    }

    const url = this.router.serializeUrl(currentNavigation.extractedUrl);
    (this.router.events as Subject<Event>).next(new NavigationCancel(currentNavigation.id, url, ''));
    (this.router as any).navigationTransitions.transitions.next({
      ...(this.router as any).navigationTransitions.transitions.getValue(),
      id: 0,
    });
  }

  public getBrowserLang(): string | null {
    return this._returnIfInLocales(getBrowserLang());
  }

  private _returnIfInLocales(value: string | null | undefined): string | null {
    if (value && this.locales && this.locales.indexOf(value) !== -1) {
      return value;
    }

    return null;
  }

  public firstLocale(): string | null {
    if (this.locales) {
      return this.locales[0];
    }

    return null;
  }
}
